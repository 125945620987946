export default defineNuxtRouteMiddleware(async () => {
  const partnerStore = usePartnerStore();

  const partner = partnerStore.getBuyoutPartner;

  if (partner?.is_manufacturer_page && (partner?.seo_name=="samsung" || partner?.parent?.seo_name=="samsung")) {
    setPageLayout("samsung-default");
    return;
  }

  setPageLayout("header-footer");
  return;
});
